import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"

export const ListOfSocialNetworks: React.FC<{
  itemStyle?: React.CSSProperties
}> = (props) => {
  const social = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          social {
            name
            url
          }
        }
      }
    }
  `).site.siteMetadata.social

  return social.map(({ name, url }) => (
    <li key={name}>
      <a
        rel="me noopener"
        className="button"
        href={url}
        style={props.itemStyle}
        target="_blank"
      >
        {name}
      </a>
    </li>
  ))
}
